


























































import Vue from "vue";

export default Vue.extend({
  props: ["total", "totalOnline", "money", "tenantId", "totalError"],
  components: {},
  data() {
    return {
      prefix: "/#/app/cashback/",
    };
  },
  methods: {},
  created() {

  },
  destroyed() {
  },
});
