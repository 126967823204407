




























import Vue from "vue";

export default Vue.extend({
    props: ["name", "address", "distance", "numberAvailable", "numberBusy", "numberOffline", "totalChargingStations"],
    components: {},
    data() {
        return {
            STATUS: {
                busy: "Bận",
                available: "Khả dụng",
                unavailable: "Không khả dụng"
            },
            TEXT_COLOR: {
                busy: "text-yellow",
                available: "text-green",
                unavailable: "text-gray"
            },
            DOT_COLOR: {
                busy: "dot-yellow",
                available: "dot-green",
                unavailable: "dot-gray"
            },
            BORDER: {
                busy: "chargeBusy",
                available: 'chargeAvailable',
                unavailable: "chargeNotAvailable"
            },
        };
    },
    methods: {
        theColor(numberAvailable) {
            if (numberAvailable === 0) return "#EAECF0";
            else if (numberAvailable > 0) return "#ECFDF3";
            else return "#ECFDF3";
        },
        getStatusMaker(numberAvailable, numberOffline, totalChargingStations) {
            let status = "busy";
            if (numberAvailable > 0) {
                status = "available";
            }
            if (numberOffline == totalChargingStations) {
                status = "unavailable";
            }

           return status;
        },
    },
    created() {

    },
    destroyed() {
    },
});
