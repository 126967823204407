var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('BlurredTenant',{attrs:{"showBlur":_vm.showBlur}},[_c('ChargeStatistic',{attrs:{"total":_vm.total,"totalOnline":_vm.totalOnline,"tenantId":_vm.tenantId,"money":_vm.money,"totalError":_vm.totalError}}),_c('div',{staticClass:"container-map pa-4"},[_c('span',{staticClass:"mb-4 font-weight-bold"},[_vm._v("LIST OF STATIONS")]),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false,
                    clickableIcons: false
                }},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},_vm._l((_vm.coordinates),function(marker){return _c('gmap-custom-marker',{key:marker.id,attrs:{"marker":marker},nativeOn:{"click":function($event){return _vm.handleMarkerClick(marker)}}},[_c('popper',{attrs:{"trigger":"clickToOpen","options":{
                        placement: 'top',
                        modifiers: { offset: { offset: '0,10px' } }
                        }}},[_c('div',{staticClass:"popper"},[_c('ChargeStationInfo',{attrs:{"name":marker.name,"address":marker.address,"distance":_vm.calculateDistance(marker.lat, marker.lng),"numberAvailable":marker.numberAvailable,"numberOffline":marker.numberOffline,"totalChargingStations":marker.totalChargingStations,"numberBusy":marker.totalChargingStations - (marker.numberAvailable + marker.numberOffline)}})],1),_c('button',{attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticClass:"icon_maker",staticStyle:{"max-width":"40px","max-height":"40px"},attrs:{"src":marker.numberAvailable > 0 ? _vm.iconActive : (marker.numberOffline === marker.totalChargingStations ? _vm.iconInActive : _vm.iconBusy)}})])])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }