<template>
    <v-container fluid>
    
        <BlurredTenant :showBlur="showBlur">
            <ChargeStatistic :total="total" :totalOnline="totalOnline" :tenantId="tenantId" :money="money"
                :totalError="totalError" />
            <div class="container-map pa-4">
              
                <span class="mb-4 font-weight-bold">LIST OF STATIONS</span>
                 
              
                <GmapMap
                    ref="mapRef"
                    :center="center"
                    :zoom="zoom"
                    map-type-id="terrain"
                    style="width: 100%; height: 100%"
                    @click.stop="handleClick"
                    :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false,
                        clickableIcons: false
                    }"
                >
                    <gmap-custom-marker
                        v-for="marker in coordinates"
                        :key="marker.id"
                        :marker="marker"
                        @click.native="handleMarkerClick(marker)"
                    >
                        <popper
                            trigger="clickToOpen"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                            }">
                            <div class="popper">
                                <ChargeStationInfo :name="marker.name" :address="marker.address"
                                    :distance="calculateDistance(marker.lat, marker.lng)"
                                    :numberAvailable="marker.numberAvailable"
                                    :numberOffline="marker.numberOffline"
                                    :totalChargingStations="marker.totalChargingStations"
                                    :numberBusy="marker.totalChargingStations - (marker.numberAvailable + marker.numberOffline)"
                                    
                                />
                            </div>
                            <button slot="reference">
                                <img class="icon_maker" :src="marker.numberAvailable > 0 ? iconActive : (marker.numberOffline === marker.totalChargingStations ? iconInActive : iconBusy)" style="max-width: 40px; max-height: 40px" />
                            </button>
                        </popper>
                    </gmap-custom-marker>
                  
                </GmapMap>
              
            </div>
        </BlurredTenant>
    </v-container>
</template>


<script>
import Vue from "vue";
import iconActive from '@/assets/images/icon_location_active.png';
import iconInActive from '@/assets/images/icon_location_in_active.png';
import iconBusy from '@/assets/images/icon_location_busy.png';
import { latLng } from "leaflet";
import coreApiClient from "@/services/apis/coreApiClient";
import ChargeStatistic from "@/views/app/map/ChargeStatistic.vue";
import ChargeStationInfo from "@/views/app/map/ChargeStationInfo.vue";
import userManager from "@/services/userManager";
import BlurredTenant from '@/components/BlurredTenant.vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default Vue.extend({
    name: "Maps",
    components: {
        BlurredTenant,
        ChargeStationInfo,
        ChargeStatistic,
        GmapCustomMarker,
        'popper': Popper

    },
    data() {
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            money: 0,
            tenantId: null,
            zoom: 11,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            attributionControl: false,
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true,
            coordinates: [],
            total: null,
            position: null,
            locationLoading: false,
            iconActive: iconActive,
            iconInActive: iconInActive,
            iconBusy: iconBusy,
            iconSize: [30, 40],
            totalOnline: null,
            totalError: null,
            
            center: { lat: 21.0016555, lng: 105.8203087 },
            markers: [],
            showPopup: false, // Initially hidden
            selectedMarker: null,


        };
    },
    methods: {
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
            this.zoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
            this.center = center;
        },
        getCoord(a, b) {
            return latLng(a, b)
        },
        handleMarkerClick(marker) {
             this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo({lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)})
            })
            this.coordinates.forEach(m => m.showPopup = false); // Reset all popups
            const markerIndex = this.coordinates.findIndex(m => m.id === marker.id);
            this.coordinates.splice(markerIndex, 1, { // Update specific marker
                ...marker,
                showPopup: !marker.showPopup,
            });
            if (this.zoom < 12) {
                this.zoom = 12;
            }
        },
        handleClosePopup() {
            const coordinatesNew = this.coordinates.forEach(m => m.showPopup = false); // Reset all popups
            console.log("coordinatesNew", coordinatesNew)
            coordinatesNew
        },
      
        async getLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        resolve(position);
                    }, reject);
                } else {
                    reject(new Error("Geolocation is not supported by this browser."));
                }
            });
        },
        async getCurrentLocation() {
            {
                try {
                    this.locationLoading = true;
                    const position = await this.getLocation();
                    this.position = position;
                    this.center = { lat: position.coords.latitude, lng: position.coords.longitude };
                    this.currentCenter = latLng(position.coords.latitude, position.coords.longitude);
                } catch (e) {
                    console.log(e);
                } finally {
                    this.locationLoading = false;
                }
            }
        },

        calculateDistance(tolat, tolon) {
            if (this.position && tolat && tolon) {
                const earthRadius = 12742;
                const mathPi = 0.017453292519943295; // (Math.PI / 180) for converting to radians
                const cos = Math.cos; // Cos function
                const distanceFactor = 0.5 - cos((tolat - this.position.coords.latitude) * mathPi) / 2 +
                    cos(this.position.coords.latitude * mathPi) * cos(tolat * mathPi) *
                    (1 - cos((tolon - this.position.coords.longitude) * mathPi)) / 2;
                return parseFloat(Number(earthRadius * Math.asin(Math.sqrt(distanceFactor))).toFixed(1));
            }
            return null;
        },
        async getCashbackInfo() {
            try {
                const { cashback } = await coreApiClient.call("chargingSessions", "cashbackInfo", {});
                this.money = parseInt(cashback ? cashback : 0);
                this.tenantId = userManager.getTenantId();
            } catch (e) {
                console.error(e);
            }
        },
        /// Get danh sách vị trí trạm sạc
        async getChargingLocation() {
            try {
                const filters = [
                    {
                        key: "_id",
                        operator: "equal_to",
                    },
                ];
                const { items } = await coreApiClient.call(
                    "chargingLocations",
                    "findAll",
                    {
                        payload: JSON.stringify({
                            limit: 1000,
                            search: null,
                            orderBy: "name",
                            orderType: "asc",
                            filters,
                        }),
                    }
                );
                this.coordinates = items;
                console.log("this.coordinates", this.coordinates)
                this.markers = items;
                this.total = 0;
                this.totalOnline = 0;
                this.totalError = 0;
                items.forEach((item) => {
                    this.total += item.totalChargingStations;
                    this.totalOnline += item.numberAvailable;
                    this.totalError += item.numberError;
                });
            } catch (e) {
                console.error(e)
            }
        },

    },
    watch: {},
    async mounted() {
        await this.getCurrentLocation();
        await this.getChargingLocation();
        if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true)) {
            await this.getCashbackInfo();
        }
    },
    created() {
    },
    destroyed() {
    },
});


</script>
<style>
.leaflet-popup-content {
    width: 224px;
}

.popup {
  position: absolute; /* Position relative to the map */
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure popup appears above markers */
}

.leaflet-popup-close-button {
    display: none;
}

.total-charge {
    display: flex;
    flex-direction: row;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    width: 100%;
}

.container-map {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background: #ffffff;
    height: 900px !important;
}


.content {
    position: relative;
    z-index: 2;
    padding: 20px;
    background: white;
}

.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}

.message {
    z-index: 3;
}

.blurred-section {
    position: relative;
    overflow: hidden;
}

.popper {
    width: 300px;
    border-radius: 20px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    z-index: 10000 !important;
}
.marker {
    z-index: 0 !important;
}
</style>
